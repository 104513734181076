// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-routes-book-page-js": () => import("./../../../src/routes/BookPage.js" /* webpackChunkName: "component---src-routes-book-page-js" */),
  "component---src-routes-explore-page-js": () => import("./../../../src/routes/ExplorePage.js" /* webpackChunkName: "component---src-routes-explore-page-js" */),
  "component---src-routes-landing-page-js": () => import("./../../../src/routes/LandingPage.js" /* webpackChunkName: "component---src-routes-landing-page-js" */)
}

